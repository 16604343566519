@font-face {
  font-family: 'Geometria-bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Geometria-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Geometria-regular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Geometria.ttf') format('truetype');
}

body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  letter-spacing: 0.025rem;
}

body.modal-open {
  overflow: hidden;
}

/* Client wants Geometria for headings */

h1 {
  font-family: 'Geometria-bold', 'Roboto', sans-serif;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.05rem;
}

h2 {
  font-family: 'Geometria-bold', 'Roboto', sans-serif;
  font-weight: 300;
  letter-spacing: 0.05rem;
}

h3 {
  font-family: 'Geometria-bold', 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 0.05rem;
}

a {
  text-decoration: none;
  color: black;
}
